import { storeToRefs } from 'pinia'
import debounce from 'lodash/debounce'
import { useAuthStore } from '~/stores/auth'
import { useCartStore } from '~/stores/cart'
import {
  GA_CLIENT_ID_KEY,
  GA_SESSION_ID_KEY,
  SC_TOKEN_COOKIE_KEY
} from '~/utils/constants'

export default function () {
  if (import.meta.server) {
    return false
  }
  const authStore = useAuthStore()
  const cartStore = useCartStore()

  const storeType = useStoreType()
  const {
    token,
    refresh,
    customerId,
    tokenStore,
    refreshStore,
    rememberCustomer
  } = storeToRefs(authStore)
  const cartToken = computed(() => cartStore.cartToken)
  const scCookie = useCookie(SC_TOKEN_COOKIE_KEY, { watch: true })

  const sync = debounce(() => {
    refreshCookie(SC_TOKEN_COOKIE_KEY)
    refreshCookie(GA_SESSION_ID_KEY)
    refreshCookie(GA_CLIENT_ID_KEY)
    const gaSessionIdCookie = useCookie(GA_SESSION_ID_KEY)
    const gaClientIdCookie = useCookie(GA_CLIENT_ID_KEY)
    if (gaClientIdCookie.value && gaClientIdCookie.value) {
      console.log('sync_ga: ', {
        session: gaSessionIdCookie.value,
        client: gaClientIdCookie.value
      })
    }
    try {
      const host = storeType.isHunting
        ? storeType.fishingDomain
        : storeType.huntingDomain
      let url
      if (authStore.loggedIn) {
        url = `${host}/sync-cookies/?token=${token.value || ''}&refresh=${
          refresh.value || ''
        }&customerId=${
          customerId.value || ''
        }&tokenStore=&refreshStore=&rememberCustomer=${
          rememberCustomer.value ?? ''
        }&cartToken&gaClientId=${gaClientIdCookie.value || ''}&gaSessionId=${
          gaSessionIdCookie.value || ''
        }`
      } else if (authStore.loggedInStore) {
        url = `${host}/sync-cookies/?token=&refresh=&customerId=&tokenStore=${
          tokenStore.value || ''
        }&refreshStore=${refreshStore.value || ''}&rememberCustomer=${
          rememberCustomer.value ?? ''
        }&cartToken=${cartToken.value || ''}&scToken=${
          scCookie.value || ''
        }&gaClientId=${gaClientIdCookie.value || ''}&gaSessionId=${
          gaSessionIdCookie.value || ''
        }`
      } else {
        url = `${host}/sync-cookies/?token=&refresh=&customerId=&tokenStore=&refreshStore=&rememberCustomer=&cartToken=${
          cartToken.value || ''
        }&scToken=${scCookie.value || ''}&gaClientId=${
          gaClientIdCookie.value || ''
        }&gaSessionId=${gaSessionIdCookie.value || ''}`
      }
      // $fetch(url, { mode: 'no-cors' }).catch(e => {
      //   console.log(e)
      // })
      // return true
      if (!document || isSafari()) {
        return false
      }
      setTimeout(() => {
        const iframe = document.createElement('iframe')
        iframe.style.display = 'none'
        iframe.id = 'syncCookiesID'
        iframe.setAttribute('src', url)
        // iframe.setAttribute('sandbox', 'allow-storage-access-by-user-activation allow-scripts allow-same-origin')
        iframe.setAttribute('sandbox', 'allow-scripts allow-same-origin')
        iframe.onload = () => {
          window &&
            window.addEventListener('message', event => {
              if (event.data === 'requestStorageAccess') {
                document.body.style.overflowY = 'hidden'
                document.body.style.pointerEvents = 'none'

                Object.assign(iframe.style, {
                  position: 'fixed',
                  width: '320px',
                  minHeight: '180px',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%, -50%)',
                  borderRadius: '5px',
                  zIndex: '9999',
                  display: 'block',
                  boxShadow: '0 5px 5px 3px rgba(0, 0, 0, 0.2)',
                  pointerEvents: 'auto'
                })
              }
            })
          window.addEventListener('message', event => {
            if (event.data === 'closeSyncCookiesWindow') {
              console.log('remove sync iframe')
              document.body.style.overflowY = 'visible'
              document.body.style.pointerEvents = 'auto'
              iframe.remove()
            }
          })
          // console.log('iframe', iframe)
          // console.log('iframe.window', iframe.window)
          // console.log('iframe.contentWindow', iframe.contentWindow)

          // iframe.contentWindow.closeIframe = () => { console.log('close iframe MTF') }
          // iframe.contentWindow.closeIframe = () => { console.log('close iframe MTF') }
          // setTimeout(() => {
          //   try {
          //     iframe && iframe.remove()
          //   } catch (e) {
          //     console.log('error on delete iframe')
          //   }
          // }, 10000)
        }
        document.getElementsByTagName('body')[0].appendChild(iframe)
        // window.addEventListener('message', event => {
        //   if (event.data === 'closeSyncCookiesWindow') {
        //     console.log('im in event')
        //     document.body.style.overflowY = 'visible'
        //     iframe.remove()
        //   }
        // }, {
        //   once: true
        // })
      }, 500)
    } catch (e) {
      console.log('sync error')
      console.error(e)
    }
  }, 1000)

  const setGaCookies = () => {
    const gaSessionIdCookie = useCookie(GA_SESSION_ID_KEY)
    const gaClientIdCookie = useCookie(GA_CLIENT_ID_KEY)
    const prefix = storeType.isHunting ? 'w_' : 'g_'
    const gaCookiePattern = /_ga_[A-Z0-9]{10}/
    const getId = (cookieValue, isClientId) => {
      const splitString = cookieValue?.split('.')
      if (splitString && splitString.length > 3) {
        return isClientId
          ? `${prefix}${splitString[2]}.${splitString[3]}`
          : `${prefix}${splitString[2]}`
      }
      return ''
    }

    const isValidCookie = cookieValue =>
      cookieValue && !cookieValue.includes(prefix)

    if (
      isValidCookie(gaSessionIdCookie.value) ||
      isValidCookie(gaClientIdCookie.value)
    ) {
      return false
    }
    const cookies = getAllCookies()

    const sessionIdKey = Object.keys(cookies)?.find(cookieKey =>
      gaCookiePattern.test(cookieKey)
    )
    const clientIdKey = '_ga'

    if (cookies[sessionIdKey] && cookies[clientIdKey]) {
      gaSessionIdCookie.value = getId(cookies[sessionIdKey], false)
      gaClientIdCookie.value = getId(cookies[clientIdKey], true)
      sync()
    }
  }

  if (scCookie.value) {
    sync()
  }
  checkCookiesExist('_ga', setGaCookies, 12000, 1000)

  watch(() => scCookie.value, sync)
  watch(token, sync)
  watch(tokenStore, sync)
  watch(cartToken, () => {
    if (!authStore.loggedIn) {
      sync()
    }
  })
}

const getAllCookies = () => {
  const parseCookies = cookieString => {
    return Object.fromEntries(
      cookieString.split('; ').map(cookie => {
        const [name, ...rest] = cookie.split('=')
        return [name, rest.join('=')]
      })
    )
  }

  if (process.client) {
    return parseCookies(document.cookie)
  }

  if (process.server) {
    const headers = useRequestHeaders()
    const cookieHeader = headers.cookie || ''
    return parseCookies(cookieHeader)
  }

  return {}
}

const isSafari = () => {
  if (process.server) {
    return false
  }
  const userAgent = navigator.userAgent
  return (
    userAgent.includes('Safari') &&
    !userAgent.includes('Chrome') &&
    !userAgent.includes('Chromium')
  )
}

const checkCookiesExist = (
  cookieName,
  callback,
  timeout = 5000,
  interval = 1000
) => {
  const start = Date.now()

  const intervalId = setInterval(() => {
    const cookie = useCookie(cookieName).value
    if (cookie) {
      callback()
      clearInterval(intervalId)
    } else if (Date.now() - start >= timeout) {
      console.error('Timeout waiting for cookie')
      callback()
      clearInterval(intervalId)
    }
  }, interval)
}
